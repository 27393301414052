import axios from 'axios';

const booming = axios.create({
    baseURL: process.env.VUE_APP_BASE_BOOMING_URL,
});

booming.defaults.headers.post['Content-Type'] = 'application/json';
booming.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

booming.defaults.method = "post";

export default booming;