import axios from 'axios';

const jetsafi = axios.create({
    baseURL: process.env.VUE_APP_BASE_JETSAFI_URL,
});

jetsafi.defaults.headers.post['Content-Type'] = 'application/json';
jetsafi.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

jetsafi.defaults.method = "post";

export default jetsafi;